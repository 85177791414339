import React from 'react'
import Layout from '../components/layout'
import { useSpring, animated } from 'react-spring'
import { EndPoint } from '../components/nav/header-nav'
import { navigate } from 'gatsby'

export const AfterSchoolPrograms = () => {
  const springOpacity = useSpring({
    from: { opacity: 0, transform: 'translateX(90px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  })
  return (
    <Layout seoTitle="After School Programs">
      <animated.div
        style={springOpacity}
        className="bg-gray-50 overflow-hidden"
      >
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block bg-gray-100 absolute top-0 bottom-0 left-3/4 w-screen"></div>
          <div className="mx-auto text-base lg:max-w-none">
            <p className="text-base leading-6 text-accent-400 font-semibold tracking-wide uppercase">
              Available for booking now
            </p>
            <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              After School Programs
            </h1>
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2">
              <svg
                className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div className="relative text-base mx-auto lg:max-w-none">
                <figure>
                  <div className="relative pb-7/12 lg:pb-0">
                    <img
                      src="https://i1.wp.com/www.cortizfitness.com/assets/unspecified-7.jpg?fit=768%2C512&ssl=1"
                      alt="after school programs at fit kidz 4 fun"
                      width="1184"
                      height="1376"
                      className="rounded-lg shadow-lg object-cover object-center absolute inset-0 w-full h-full lg:static lg:h-auto"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div>
              <div className="text-base mx-auto lg:max-w-none">
                <p className="text-lg leading-7 text-gray-500 mb-5">
                  Our program offers kids a great way to be active in a fun way.
                  The program starts off with fun fitness activities that will
                  get your child moving. Our signature games will leave your
                  child craving more from our fun fitness program. After your
                  child is done with our fitness portion we go into our unique
                  sports program
                </p>
              </div>
              <div className="mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <p className="text-gray-400">
                  Our Sports and agility program offers various sports such as
                </p>
                <ul className="ml-6 list-disc text-gray-400">
                  <li className="font-semibold">
                    Mega Ball (Our own sports game)
                  </li>
                  <li>Pool Noodle Hockey</li>
                  <li>Flag Football</li>
                  <li>Self Defense</li>
                  <li>Basketball</li>
                  <li>Volleyball</li>
                  <li>Soccer</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <figure>
              <div className="relative pb-7/12 lg:pb-0">
                <img
                  src="https://images.unsplash.com/photo-1473663065522-33361623fa27?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
                  alt="after school programs at fit kidz 4 fun"
                  width="1184"
                  height="1376"
                  className="rounded-lg shadow-lg object-cover object-center absolute inset-0 w-full h-full lg:static lg:h-auto"
                />
              </div>
            </figure>
            <div className="relative mt-4 md:mt-0 flex flex-col justify-center">
              <p className="text-gray-500 shadow-md bg-gray-50 rounded-md p-2">
                We rotate these sports every class so your child never gets
                bored of the same old routine. They learn basic fundamentals in
                each sport giving your child something to try that they may
                love.
              </p>
              <button
                onClick={() => navigate(EndPoint.PARTICIPATING_SCHOOLS)}
                className="mt-4 text-center text-white shadow-md bg-accent-400 rounded-md p-2"
              >
                Click here to view all participating schools
              </button>
            </div>
          </div>
        </div>
      </animated.div>
    </Layout>
  )
}

export default AfterSchoolPrograms
